<template>
  <b-col cols="2" class="edit-activated-container">
    <b-button block v-b-modal.edit-gr-modal variant="outline-secondary">Edit Gold Rush Raffle</b-button>

    <b-modal
      id="edit-gr-modal"
      title="Edit Gold Rush Event Series"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <form @submit.stop.prevent="onSubmit">
        <div v-if="editMasterEventFailed" class="alert alert-danger">
          Failed to edit event series: {{ errorMessage }}
        </div>

        <b-form-row>
          <b-form-group
            label="Number of events"
            label-for="event-count"
            class="col"
            :invalid-feedback="veeErrors.first('event-count')"
          >
            <b-form-spinbutton
              name="event-count"
              v-model="eventCount"
              v-validate="{
                required: true,
                numeric: true,
                min_value: 1,
                max_value: 52
              }"
              min="1"
              max="52"
              :state="validateState('event-count')"
              aria-describedby="event-count-feedback"
              data-vv-as="number of events"
            />
          </b-form-group>

          <b-form-group label="Auto recurring" :invalid-feedback="veeErrors.first('auto-recurring')">
            <b-form-select
              name="auto-recurring"
              v-model="autoRecurring"
              aria-describedby="auto-recurring-feedback"
              :options="options"
            />
          </b-form-group>

          <b-form-group label="Print individual tickets" label-for="print-individual-tickets" class="col">
            <b-form-select
              name="print-individual-tickets"
              v-model="printIndividualTickets"
              aria-describedby="print-individual-tickets-feedback"
              :options="options"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group
            label="License end date"
            label-for="license-end-date"
            class="col"
            :invalid-feedback="veeErrors.first('license-end-date')"
          >
            <b-form-datepicker
              name="license-end-date"
              v-model="licenseEndDate"
              :state="validateState('license-end-date')"
              aria-describedby="license-end-date-feedback"
              data-vv-as="license end date"
            />
          </b-form-group>
          <b-form-group
            label="License End time"
            label-for="license-end-time"
            :invalid-feedback="veeErrors.first('license-end-time')"
            class="col"
          >
            <b-form-timepicker
              name="license-end-time"
              v-model="licenseEndTime"
              :state="validateState('license-end-time')"
              aria-describedby="license-end-time-feedback"
              data-vv-as="license end time"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Ticket Footer" label-for="ticket-footer" class="col">
            <b-form-textarea v-model="ticketFooter" name="ticket-footer" data-vv-as="ticket footer" />
          </b-form-group>
        </b-form-row>
      </form>
    </b-modal>
  </b-col>
</template>

<script>
import axios from '@/axios';
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';

export default {
  props: ['masterTicketFooter'],
  data: function () {
    return {
      eventCount: 1,
      autoRecurring: true,
      printIndividualTickets: false,
      ticketFooter: null,
      licenseEndDate: null,
      licenseEndTime: null,
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      editMasterEventFailed: false,
      errorMessage: null,
      masterEventId: '',
      formDefaults: {
        eventCount: 1,
        autoRecurring: true,
        printIndividualTickets: false,
        ticketFooter: this.masterTicketFooter ? this.masterTicketFooter : ''
      }
    };
  },

  computed: {
    organizationTimeZone() {
      return this.$store.getters.getOrganization.timeZone;
    }
  },

  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    getLocalDate(utcDateTime, localTimeZone) {
      const localDateTime = utcToZonedTime(utcDateTime, localTimeZone);
      const formattedLocalDate = format(localDateTime, 'yyyy-MM-dd');

      return formattedLocalDate;
    },
    getLocalTime(utcDateTime, localTimeZone) {
      const localDateTime = utcToZonedTime(utcDateTime, localTimeZone);
      const formattedLocalTime = format(localDateTime, 'HH:mm:ss');

      return formattedLocalTime;
    },

    getUtcDateTime(localDate, localTime, localTimeZone) {
      const localDateTime = this.parseISO(localDate + 'T' + localTime);

      return zonedTimeToUtc(localDateTime, localTimeZone);
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      try {
        await axios.patch(`/goldrush-service/master-event/${this.masterEventId}/edit-activated`, {
          numDraws: this.eventCount,
          autoRecurring: this.autoRecurring,
          printIndividualTickets: this.printIndividualTickets,
          ticketFooter: this.ticketFooter ? this.ticketFooter : '',
          licenseEndDate: this.getUtcDateTime(this.licenseEndDate, this.licenseEndTime, this.organizationTimeZone)
        });

        // refresh page to switch states
        this.$router.go();
      } catch (error) {
        this.editMasterEventFailed = error;

        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },

    resetForm() {
      this.eventCount = this.formDefaults.eventCount;
      this.autoRecurring = this.formDefaults.autoRecurring;
      this.printIndividualTickets = this.formDefaults.printIndividualTickets;
      this.ticketFooter = this.formDefaults.ticketFooter;
      this.licenseEndDate = this.formDefaults.licenseEndDate;
      this.licenseEndTime = this.formDefaults.licenseEndTime;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    }
  },

  async mounted() {
    try {
      const response = await axios.get('/goldrush-service/master-event');

      const masterEventData = response.data.data;

      // This should not happen
      if (!masterEventData.id) {
        throw new Error();
      }

      this.masterEventId = masterEventData.id;
      this.formDefaults.eventCount = masterEventData.numDraws;
      this.formDefaults.autoRecurring = masterEventData.autoRecurring;
      this.formDefaults.printIndividualTickets = masterEventData.printIndividualTickets;
      this.formDefaults.ticketFooter = masterEventData.ticketFooter;

      if (masterEventData.licenseEndDate) {
        this.formDefaults.licenseEndDate = this.getLocalDate(masterEventData.licenseEndDate, this.organizationTimeZone);
        this.formDefaults.licenseEndTime = this.getLocalTime(masterEventData.licenseEndDate, this.organizationTimeZone);
      }

      // reset the form to load defaults
      this.resetForm();
    } catch (error) {
      this.errorMessage = 'Failed to load Gold Rush event series';
      return;
    }
  }
};
</script>

<style scoped>
.edit-activated-container {
  margin-bottom: 1rem;
}
</style>
