<template>
  <div>
    <h1 class="text-2xl font-medium">Fees</h1>
    <div class="flex flex-wrap">
      <div class="flex-none w-60 mr-8">
        <b-form-group label="Rafflebox Fee Type" class="mb-4 custom">
          <b-form-select :options="feeTypes" v-model="feeType" class="w-60" />
        </b-form-group>
      </div>
      <div class="flex-none w-52 mr-8">
        <b-form-group
          v-if="canEditRaffleFees && feeType === 'percent'"
          label="Rafflebox Fee"
          label-for="input-rafflebox-fee-percent"
          :invalid-feedback="veeErrors.first('input-rafflebox-fee-percent')"
          class="mb-4 custom"
          :state="validateState('input-rafflebox-fee-percent')"
        >
          <b-input-group append="%" class="mr-3">
            <b-form-input
              name="input-rafflebox-fee-percent"
              type="number"
              step="any"
              key="fee-percent"
              :state="validateState('input-rafflebox-fee-percent')"
              v-validate="{ min_value: 0, max_value: 100, required: true }"
              aria-describedby="input-rafflebox-fee-percent-feedback"
              data-vv-as="Rafflebox Fee"
              v-model="raffleboxFeePercent"
              id="input-rafflebox-fee-percent"
              @change="raffleboxFeePercentChanged"
              class="w-40"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          v-if="canEditRaffleFees && feeType === 'flat'"
          label="Rafflebox Fee"
          label-for="input-rafflebox-fee-flat-rate"
          :invalid-feedback="veeErrors.first('input-rafflebox-fee-flat-rate')"
          class="mb-4 custom"
          :state="validateState('input-rafflebox-fee-flat-rate')"
        >
          <b-input-group prepend="$" class="mr-3">
            <b-form-input
              name="input-rafflebox-fee-flat-rate"
              type="number"
              step="any"
              key="fee-flat-rate"
              :state="validateState('input-rafflebox-fee-flat-rate')"
              v-validate="{ decimal: 2, min_value: 0, max_value: 999999, required: true }"
              aria-describedby="input-rafflebox-fee-flat-rate-feedback"
              data-vv-as="Rafflebox Fee"
              v-model="raffleboxFeeFlatRateDollars"
              id="input-rafflebox-fee-flat-rate"
              @change="raffleboxFeeFlatRateChanged"
            />
          </b-input-group>
        </b-form-group>
      </div>

      <div class="flex-none w-52 mr-8">
        <b-form-group
          v-if="canEditRaffleFees"
          label="Rafflebox Fee Per Order"
          label-for="input-rafflebox-order-fee-dollars"
          :invalid-feedback="veeErrors.first('input-rafflebox-order-fee-dollars')"
          class="mb-4"
          :state="validateState('input-rafflebox-order-fee-dollars')"
        >
          <b-input-group prepend="$" class="mr-3">
            <b-form-input
              name="input-rafflebox-order-fee-dollars"
              v-model="raffleboxOrderFeeDollars"
              type="number"
              step="any"
              :state="validateState('input-rafflebox-order-fee-dollars')"
              v-validate="{ decimal: 2, min_value: 0, max_value: 999999, required: true }"
              aria-describedby="input-rafflebox-order-fee-dollars-feedback"
              data-vv-as="Rafflebox fee per order"
              id="input-rafflebox-order-fee-dollars"
            />
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div class="flex-none w-52 mr-8">
      <b-form-group
        v-if="canEditProcessingFees"
        label-for="input-processing-fee-percent"
        :invalid-feedback="veeErrors.first('input-processing-fee-percent')"
        class="mb-4 custom"
        :state="validateState('input-processing-fee-percent')"
      >
        <template slot="label">
          Customer Processing Fee
          <sup
            v-b-tooltip.hover.top="
              'This fee is applied as a separate line item on Raffle sales and is visible to the ticket buyer upon purchase.'
            "
          >
            <i class="fa-solid fa-circle-info"></i>
          </sup>
        </template>

        <b-input-group append="%" class="mr-3">
          <b-form-input
            name="input-processing-fee-percent"
            type="number"
            step="any"
            :state="validateState('input-processing-fee-percent')"
            v-validate="{ min_value: 0, max_value: 100, required: true }"
            aria-describedby="input-processing-fee-percent-feedback"
            data-vv-as="Customer Processing Fee"
            v-model="processingFeePercent"
            id="input-processing-fee-percent"
          />
        </b-input-group>
      </b-form-group>
    </div>
    <div class="flex-none w-52 mr-8">
      <b-form-group
        v-if="canEditRaffleFees"
        label="Donation Fee"
        label-for="input-donation-fee-percent"
        :invalid-feedback="veeErrors.first('input-donation-fee-percent')"
        class="mb-4 custom"
        :state="validateState('input-donation-fee-percent')"
      >
        <b-input-group append="%" class="mr-3">
          <b-form-input
            name="input-donation-fee-percent"
            type="number"
            step="any"
            key="fee-percent"
            :state="validateState('input-donation-fee-percent')"
            v-validate="{ min_value: 0, max_value: 100, required: true }"
            aria-describedby="input-donation-fee-percent-feedback"
            data-vv-as="Donation Fee"
            v-model="raffleboxDonationFeePercent"
            id="input-donation-fee-percent"
            class="w-40"
          />
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
export default {
  data() {
    return {
      feeType: 'percent',
      feeTypes: [
        { value: 'percent', text: 'Percentage of Sales' },
        { value: 'flat', text: 'Flat Rate' }
      ],
      canEditRaffleFees: false,
      canEditProcessingFees: false
    };
  },
  async mounted() {
    if (this.feesForm.raffleboxFeePercent > 0) {
      this.feeType = 'percent';
    } else {
      this.feeType = 'flat';
    }

    this.canEditRaffleFees = await unleashFeatureEnabled(UnleashKeys.EditRaffleFees);
    this.canEditProcessingFees = await unleashFeatureEnabled(UnleashKeys.EditRaffleProcessingFees);
  },
  computed: {
    ...mapFields([
      'feesForm.raffleboxFeePercent',
      'feesForm.raffleboxDonationFeePercent',
      'feesForm.raffleboxOrderFeeDollars',
      'feesForm.processingFeePercent',
      'feesForm.raffleboxFeeFlatRateDollars'
    ]),
    feesForm() {
      return this.$store.getters.getFeesForm;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    raffleboxFeePercentChanged() {
      this.$store.commit('SET_RAFFLEBOX_FEE_FLAT_RATE_DOLLARS', 0);
    },
    raffleboxFeeFlatRateChanged() {
      this.$store.commit('SET_RAFFLEBOX_FEE_PERCENT', 0);
    }
  }
};
</script>
