<template>
  <div>
    <b-alert v-if="error" show dismissible fade variant="danger">Failed to get records: {{ error }}</b-alert>
    <b-alert :show="organizationAlert" dismissible @dismissed="organizationAlert = false" fade variant="success"
      >Successfully {{ organizationOperation }} {{ organizationName }}</b-alert
    >
    <SearchHeader ref="search">
      <template #afterSearch>
        <div class="flex flex-wrap after-search">
          <CreateOrganizationModal v-if="canCreateOrganization" @onCreated="onCreatedOrganization" class="mb-2 pr-2" />
          <b-form class="mb-2" inline>
            <b-form-checkbox name="show-paid" v-model="showPendingReviewOrganizations" size="lg" switch>
              <span class="mt-1 text-base">Show drafts only</span>
            </b-form-checkbox>
          </b-form>
        </div>
      </template>
    </SearchHeader>

    <GenericTable
      :columns="columns"
      :data="data"
      :pagination="pagination"
      :loading="loading"
      :actions="true"
      @sort="sort"
      @updateCurrentPage="updateCurrentPage"
      class="devices"
    >
      <template #name="{ row }">
        <router-link :to="{ name: 'Organization', query: { id: row.id } }">{{ row.name }}</router-link>
      </template>
      <template #createdAt="{ row }">
        {{ formatDateTime(parseISO(row.createdAt)) }}
      </template>
    </GenericTable>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import CreateOrganizationModal from '@/components/CreateOrganizationModal';
import SearchHeader from '@/components/SearchHeader';
import GenericTable from '@/components/GenericTable.vue';
import { debounce } from 'lodash';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  name: 'Organizations',
  components: {
    CreateOrganizationModal,
    SearchHeader,
    GenericTable
  },
  data() {
    return {
      search: '',
      showPendingReviewOrganizations: false,
      organizationOperation: null,
      organizationAlert: null,
      organizationName: null,
      error: null,
      alert: {
        text: '',
        model: false
      },
      columns: [
        {
          name: 'number',
          label: 'No.',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'name',
          label: 'Organization Name',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'shortlink',
          label: 'Shortlink',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'stripeConnectedAccountId',
          label: 'Stripe ID',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'createdAt',
          label: 'Created',
          sortable: true,
          classes: 'w-auto'
        }
      ],
      title: 'Organizations',
      data: [],
      selectedDevices: {},
      sortBy: 'createdAt',
      sortDir: 'desc',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      loading: false,
      canCreateOrganization: false
    };
  },
  watch: {
    search: debounce(async function () {
      await this.updateCurrentPage(0);
    }, 500),
    showPendingReviewOrganizations: async function () {
      await this.getOrganizations();
    },
    'table.sortBy': function () {
      this.refreshTable();
    }
  },
  async mounted() {
    this.$watch('$refs.search.search', (newVal) => (this.search = newVal));
    await this.getOrganizations();
    this.canCreateOrganization = await unleashFeatureEnabled(UnleashKeys.CreateOrganization);
  },
  methods: {
    onCreatedOrganization(payload) {
      this.organizationAlert = true;
      this.organizationOperation = 'created';
      this.organizationName = payload.name;
      this.refreshTable();
    },
    onEditOrganization(payload) {
      this.organizationAlert = true;
      this.organizationOperation = 'edited';
      this.organizationName = payload.name;
      this.refreshTable();
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'organizations-table');
    },
    async getOrganizations() {
      const params = {
        search: this.search.length > 0 ? this.search : undefined,
        page: this.currentPage,
        pageSize: 10,
        sortBy: this.sortBy || undefined,
        sortDir: this.sortDir
      };

      if (this.showPendingReviewOrganizations === true) {
        params.status = 'draft';
      }

      try {
        this.loading = true;
        const orgs = await OrganizationServiceV2.listOrganizations(params);
        this.pagination.total = orgs.pagination.total;
        this.data = orgs.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleError(error);
        return [];
      }
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getOrganizations();
    },
    async updateCurrentPage(page) {
      this.currentPage = page;
      await this.getOrganizations();
    },
    handleError(error) {
      if (error.message) {
        this.alert.text = error.message;
      } else {
        this.alert.text = error;
      }
      this.alert.model = true;
    }
  }
};
</script>
