var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"px-4"},[_c('h1',[_vm._v("All Imports")]),(_vm.alertMessage)?_c('Alert',{attrs:{"variant":_vm.alertVariant}},[_vm._v(_vm._s(_vm.alertMessage))]):_vm._e(),_c('Alert',{attrs:{"variant":"green"}},[_vm._v(" The "),_c('b',[_vm._v("Imported")]),_vm._v(" value may change as the import progresses. Once your import has passed the validation step the Imported will slowly increase. "),_c('br'),_vm._v(" Imports of "),_c('b',[_vm._v("Type")]),_vm._v(" \"Order\" will show a completed status before the import is fully completed. Refer to Validated/Imported counts for progress. ")])],1),_c('div',{staticClass:"overflow-x-hidden px-4 mt-10"},[_c('div',{staticClass:"table-overflow"},[_c('GenericTable',{attrs:{"columns":_vm.columns,"data":_vm.data,"actions":true,"loading":_vm.loading,"pagination":_vm.pagination,"totalName":_vm.totalName,"stacked":""},on:{"updateCurrentPage":_vm.updateCurrentPage,"sort":_vm.sort},scopedSlots:_vm._u([{key:"jobId",fn:function(ref){
var row = ref.row;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(row.uuid),expression:"row.uuid",modifiers:{"hover":true}}],staticClass:"dashline"},[_vm._v(" "+_vm._s(_vm.formatUuid(row.uuid))+" ")])]}},{key:"completedAt",fn:function(ref){
var row = ref.row;
return [_c('DateTooltip',{attrs:{"date":row.completedAt}})]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 'completed')?_c('Badge',{attrs:{"variant":"success","label":"Completed"}}):(row.status === 'in-progress')?_c('Badge',{attrs:{"variant":"secondary","label":"In Progress"}}):(row.status === 'pending')?_c('Badge',{attrs:{"variant":"light","label":"Pending"}}):(row.status === 'failed')?_c('Badge',{attrs:{"variant":"danger","label":"Failed"}}):_c('Badge',{attrs:{"variant":"danger","label":row.status}})]}},{key:"service",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.service.charAt(0).toUpperCase() + row.service.slice(1)))])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }