var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mb-7"},[(_vm.errorMessage)?_c('Alert',{attrs:{"variant":"red","icon":"exclamation"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('h1',{staticClass:"px-3"},[_vm._v("Customers")]),_c('div',{staticClass:"container-search flex flex-row flex-wrap items-center justify-between"},[_c('div',{staticClass:"px-4 flex flex-row xs:flex-wrap md:flex-nowrap flex-1 items-center w-full"},[_c('b-form-input',{staticClass:"md:mr-4 md:max-w-xs",attrs:{"placeholder":"Enter search","debounce":"300"},model:{value:(_vm.form.input),callback:function ($$v) {_vm.$set(_vm.form, "input", $$v)},expression:"form.input"}}),_c('div',{staticClass:"flex flex-row items-center justify-between xs:w-full xs:mt-3 sm:w-full md:mt-0 md:flex-1"},[_c('div',{staticClass:"flex"},[_c('CreateCustomer',{on:{"createCustomer":_vm.refreshTable}}),(_vm.showImportModal && !_vm.showImportModalV2)?_c('ImportCustomersModal',{staticClass:"ml-4",on:{"importCustomers":_vm.refreshTable}}):_vm._e(),(_vm.showImportModalV2)?_c('FileImporterModal',{staticClass:"ml-3",attrs:{"service":"customer","sampleUrl":"https://rafflebox-docs.s3.ca-central-1.amazonaws.com/customer-import-example.csv"}}):_vm._e(),(_vm.enableExportCustomers)?_c('DownloadFile',{staticClass:"ml-4",attrs:{"retrieveData":_vm.exportCustomers,"filename":"customers.csv"},on:{"error":_vm.exportError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var downloadFile = ref.downloadFile;
return [_c('BaseButton',{attrs:{"variant":"secondary-outline","icon":"arrow-down-to-line","loading":_vm.buttonLoading},nativeOn:{"click":function($event){return downloadFile($event)}}},[_vm._v(" Export ")])]}}],null,false,2616379332)}):_vm._e()],1),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"switch":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._v("Show active")])],1)],1)]),_c('div',{staticClass:"overflow-x-hidden"},[_c('div',{staticClass:"col"},[_c('GenericTable',{staticClass:"customers",attrs:{"columns":_vm.columns,"data":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"actions":true},on:{"sort":_vm.sort,"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"phone",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phone ? _vm.formatPhone(row.phone) : '')+" ")]}},{key:"createdAt",fn:function(ref){
var row = ref.row;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"dashline",attrs:{"title":row.createdAt
                ? _vm.formatDateTime(_vm.parseISO(row.createdAt), {
                    format: 'dateTime',
                    timeZone: _vm.timeZone,
                    displayTimeZone: true
                  })
                : ''}},[_vm._v(" "+_vm._s(row.createdAt ? _vm.formatDateTime(_vm.parseISO(row.createdAt)) : '')+" ")])]}},{key:"actions",fn:function(ref){
                var row = ref.row;
return [_c('router-link',{staticClass:"edit-link",attrs:{"to":{ name: 'Customer', query: { id: row.id } }}},[_c('span',[_c('i',{staticClass:"fa-regular fa-pen-to-square"})])])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }